<template>
  <a-spin :spinning="loading">
    <div class="payment-order-wrap wrapper" v-if="payment_order">
      <a-empty
        v-if="payment_order.length == 0"
        description="暂无缴费记录"
      ></a-empty>
      <div v-else>
        <template v-for="(item, index) in payment_order" :key="index">
          <div class="payment-order-item">
            <div class="flex flex-between payment-order-top">
              <div class="font-18">订单号：{{ item.order_no }}</div>
              <div class="font-18 text-gray">{{ item.order_create_time }}</div>
            </div>
            <div class="flex flex-between payment-order">
              <div class="font-24 text-blue">¥{{ item.order_value }}</div>
              <div class="flex">
                <div
                  :class="{
                    'order-label': true,
                    'label-red': item.order_status == 1
                  }"
                >
                  {{ item.order_status == 1 ? "已支付" : "未支付" }}
                </div>
                <div
                  v-if="item.order_status == 0"
                  class="pay-btn"
                  @click="updateOrderStatus(item)"
                >
                  去支付
                </div>
              </div>
            </div>
            <div
              class="order-detail-item flex flex-between"
              v-for="(item2, index2) in item.order_detail"
              :key="index2"
            >
              <div>
                <div>
                  <span class="font-20 font-bold">{{
                    item2.payment_type_name
                  }}</span>
                  <span class="text-gray ml-20 mr-20">|</span>
                  <span class="text-gray font-18">{{ item2.site_name }}</span>
                  <span class="text-gray ml-20 mr-20">|</span>
                  <span class="text-gray font-18">
                    {{ item2.payment_start_time }}-{{
                      item2.payment_end_time
                    }}</span
                  >
                </div>
                <div v-if="item2.payment_remark" class="font-16 mt-10 remark text-gray">{{item2.payment_remark}}</div>
              </div>

              <div class="font-20">¥{{ item2.detail_value }}</div>
            </div>
          </div>
        </template>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:pageSize="limit"
        :defaultPageSize="15"
        :hideOnSinglePage="true"
        show-size-changer
        :total="total"
        :pageSizeOptions="['15', '30', '60', '120']"
        @showSizeChange="onShowSizeChange"
      />
    </div>
    <!-- 支付弹窗 -->
    <a-modal
      v-model:visible="visible"
      :closable="true"
      width="500px"
      :footer="null"
      :maskClosable="false"
      wrapClassName="payment-modal-box"
    >
      <div class="payment-modal-title">扫码支付</div>
      <div class="qrcode-wrap">
        <qrcode-vue :value="qrcodeText" size="300" level="H" />
      </div>
      <div class="handle-box flex">
        <div class="handle-btn" @click="updatePaymentOrderCancelDataFun">
          取消订单
        </div>
        <div
          class="handle-btn handle-btn-active"
          @click="updatePaymentOrderConfirmData"
        >
          我已支付
        </div>
      </div>
    </a-modal>
  </a-spin>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import url from "@/utils/url.js";
import {
  getPaymentOrderList,
  updatePaymentOrderPayData,
  updatePaymentOrderCancelData
} from "@/api/main.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import QrcodeVue from "qrcode.vue";
export default defineComponent({
  name: "PaymentRecord",
  components: {
    QrcodeVue
  },
  setup() {
    const pageData = reactive({
      payment_order: [],
      loading: true,
      limit: 15,
      page: 1,
      total: 0,
      user_id: computed(() => getUserId()),
      qrcodeText: null,
      visible: false,
      order_id: null,
      detail_visible: false
    });
    const getPaymentOrderListFun = () => {
      pageData.loading = true;
      getPaymentOrderList({
        page: pageData.page,
        limit: pageData.limit,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.payment_order = res.data.payment_order;
            pageData.total = res.count;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    getPaymentOrderListFun();
    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };
    // 生成支付二维码
    const updatePaymentOrderPayDataFun = order_id => {
      updatePaymentOrderPayData({
        user_id: pageData.user_id,
        order_id: order_id,
        trade_type: "NATIVE"
      })
        .then(res => {
          if (res.code == 200) {
            pageData.qrcodeText = res.data.wxpay.code_url;
            pageData.visible = true;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        });
    };
    //取消支付
    const updatePaymentOrderCancelDataFun = () => {
      updatePaymentOrderCancelData({
        user_id: pageData.user_id,
        order_id: pageData.order_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.qrcodeText = null;
            pageData.visible = false;
            pageData.order_id = null;
            getPaymentOrderListFun();
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        });
    };
    const updateOrderStatus = item => {
      updatePaymentOrderPayDataFun(item.order_id);
      pageData.order_id = item.order_id;
    };
    //我已支付订单
    const updatePaymentOrderConfirmData = () => {
      getPaymentOrderListFun();
      pageData.qrcodeText = null;
      pageData.visible = false;
      pageData.order_id = null;
    };
    watch(
      [() => pageData.page, () => pageData.limit],
      val => {
        getPaymentOrderListFun();
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      onShowSizeChange,
      updateOrderStatus,
      updatePaymentOrderConfirmData,
      updatePaymentOrderCancelDataFun
    };
  }
});
</script>

<style lang="less">
.payment-order-wrap {
  padding: 50px 0;
  box-sizing: border-box;
  .payment-order-item {
    box-shadow: 0px 0px 27px 0px #eeeeee;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .payment-order-top {
    height: 66px;
    border-bottom: 1px dashed #7c7c7c;
  }
  .payment-order {
    padding: 10px 0;
    .order-label {
      width: 70px;
      height: 22px;
      text-align: center;
      line-height: 22px;
      border-radius: 11px;
      color: #9d9d9d;
      border: 1px solid #9d9d9d;
    }
    .pay-btn {
      width: 80px;
      height: 22px;
      text-align: center;
      line-height: 22px;
      border-radius: 11px;
      color: #1276cb;
      background: #1276cb;
      color: #ffffff;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .order-detail-item {
    margin-top: 10px;
    align-items: baseline;
    .remark{
        background-color: #f3f5f8;
        padding: 5px 10px;
    }
  }
  .ant-pagination {
    text-align: center;
    margin-top: 30px;
  }
}
.payment-modal-box {
  .ant-modal-content {
    width: 500px;
    height: 550px;
    background-image: linear-gradient(
        0deg,
        #ffffff 0%,
        rgba(18, 118, 203, 0.3) 100%
      ),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    border-radius: 20px;
  }
  .payment-modal-title {
    text-align: center;
    margin-top: 26px;
    font-size: 30px;
    font-weight: bold;
  }
  .qrcode-wrap {
    margin: 20px auto;
    display: flex;
    justify-content: center;
  }
  .handle-btn {
    width: 200px;
    height: 45px;
    line-height: 45px;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    color: #212531;
    font-size: 18px;
    border: 1px solid #999999;
    margin: 0 10px;
    margin-top: 20px;
  }
  .handle-btn-active {
    background-color: #1276cb;
    border-color: #1276cb;
    color: #fff;
  }
}
</style>
